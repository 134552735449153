.test-journal-page {
  width: 100%;
  min-height: calc(100vh - 84px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--background_color);
  padding: 20px 12px;
  margin: 0 auto;
}

.test-journal-page .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}



@media (min-width: 768px) {
  .test-journal-page .container > div {
    width: 50%;
  }
  
  .test-journal-page .container {
    flex-direction: row;
    align-items: flex-start;
  }
}


.test-journal-page .test-journal-button {
  max-width: 320px;
  margin: 0 auto;
  height: 55px;
}

.test-journal-page .testing-journal {
  max-width: 400px;
  margin: 0 auto;
}

.test-journal-page h1 {
  padding-bottom: 10px;
}

.test-journal-page ul {
  list-style-position: outside;
  text-align: left;
}

.no-bullet {
  list-style-type: none;
}

.divider {
  margin: 20px 0;
  background-color: var(--text_color);
  height: 2px;
}