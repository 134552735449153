hr.stayInformed {
    border: none;
    border-top: 2px solid var(--text_color);
    margin: 20px 0; 
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.7;
}

.text-with-icon {
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.text-with-icon p {
    margin-bottom: 0px;
}

.text-with-icon img {
    width: 40px;
    height: 40px;
}

.text-with-icon div {
    display: flex;
    flex-direction: column;
}

.text-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
  }

.next-steps-module h2 {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px; 
    text-align: left;
}

.map {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--text_color);
}

.map img {
    width: 80%;
    height: auto;
}

@media (max-width: 768px) {
    .map img {
        display: none;
    }
}